@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

nav.sidebar > ul > li {
  & > a {
    @apply flex items-center space-x-2 py-3 px-4 md:px-6 text-sky-200;
    & > svg { @apply opacity-50 }

    &:hover {
      @apply bg-sky-700 text-sky-50;
      & > svg { @apply opacity-75; }
    }
  }

  & > ul.sub-nav {
    @apply pl-10 py-1 space-y-1 hidden;

    & > li > a {
      @apply block text-sm py-1.5 px-3 text-sky-200 rounded-l-lg;
    }
  }

  &.active {
    & > a {
      @apply bg-sky-700 text-sky-50;
      & > svg { @apply opacity-100; }
    }

    & > ul.sub-nav {
      @apply block;

      & > li > a:hover {
        @apply bg-sky-700;
      }

      & > li.active > a {
        @apply font-semibold text-sky-100 bg-sky-700;
      }
    }
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-none {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

.list-group-item.active a,
.list-group-item.active a:hover  {
  color: white;
}

details.dropdown summary {
  outline: none;
  cursor: pointer;
  list-style: none;
}
details.dropdown summary::-webkit-details-marker {
  display: none;
}
details.is-toggleable summary::-webkit-details-marker {
  display: none;
}
details.is-toggleable summary {
  list-style: none;
}

main > nav a {
  @apply text-gray-700 hover:bg-gray-200 hover:text-gray-900;

  &.current {
    @apply  bg-sky-700 text-white;
  }
}

body > nav {
  a {
    @apply text-gray-500;
    & > svg {
      @apply text-gray-400;
    }

    &.current {
      @apply text-sky-800;

      & > svg { 
        @apply text-sky-700;
      }
    }
  }
}

nav#widget-switcher {
  @apply inline-flex space-x-0.5 rounded-full border border-gray-200 p-0.5 bg-gray-50;

  & > a {
    @apply text-sm text-gray-700 rounded-full leading-relaxed px-3 py-1 hover:text-sky-50 hover:bg-sky-700;
    
    &.active {
      @apply text-sky-50 bg-sky-700;
    } 
  }
}

nav#mobile-tabs > a {
  @apply flex items-center place-content-center space-x-2 border-b-2 border-transparent pb-2 text-gray-600 text-sm font-medium leading-loose;

  &:hover {
    @apply text-gray-900 border-gray-200
  }

  &.active {
    @apply border-gray-900;

    & > svg {
      @apply text-gray-900;
    }
  }
}

nav#overview-tabs {
  @apply flex space-x-4 md:space-x-6 border-b border-gray-200 text-sm md:text-base;

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

nav#overview-tabs > a {
  @apply relative text-gray-600 border-b-2 border-transparent leading-relaxed py-2 font-medium md:font-normal -mb-0.5;

  &:after {
    content: "";
    @apply absolute bottom-0 left-0 right-0 h-0.5 rounded-full bg-transparent w-full;
  }

  &:hover {
    @apply text-gray-800;
    &:after { @apply bg-sky-200; }
  }

  &.active {
    @apply text-sky-800 border-sky-800;
    &:after { @apply bg-sky-800 };
  }
}

.event-status {
  @apply inline-flex items-center rounded-full p-1 border;

  &.-pre-show {
    @apply bg-yellow-200 text-yellow-700 border-yellow-300;
  }

  &.-in-process {
    @apply bg-orange-200 text-orange-700 border-orange-300;
  }

  &.-ended {
    @apply bg-green-200 text-green-700 border-green-300;
  }

  &.-closed {
    @apply bg-sky-200 text-sky-700 border-sky-300;
  }

  &.-canceled {
    @apply bg-gray-200 text-gray-700 border-gray-300;
  }
}

.user-type {
  @apply inline-flex items-center rounded-full px-1 border h-7;

  &.-admin {
    @apply bg-sky-200 text-sky-700 border-sky-300;
  }

  &.-manager {
    @apply bg-orange-200 text-orange-700 border-orange-300;
  }

  &.-demonstrator {
    @apply bg-green-200 text-green-700 border-green-300;
  }
}

.show-assignment-indicator {
  @apply size-3 md:size-5 inline-flex items-center justify-center rounded-lg text-gray-200 md:bg-gray-100 md:text-gray-100 text-xs font-mono;

  &.-primary {
    @apply md:bg-sky-200 text-sky-600;

    &.-current {
      @apply md:bg-sky-600 md:text-sky-100 text-sky-900;
    }
  }

  &.-secondary {
    @apply md:bg-orange-200 md:text-orange-700 text-orange-600;
    
    &.-current {
      @apply md:bg-orange-600 md:text-orange-100 text-orange-900;
    }
  }

  &.-trainee {
    @apply md:bg-teal-200 md:text-teal-700 text-teal-600;
    
    &.-current {
      @apply md:bg-teal-600 md:text-teal-100 text-teal-900;
    }
  }

  &.-holiday {
    @apply md:bg-red-200 md:text-red-700 text-red-600;
    
    &.-current {
      @apply md:bg-red-600 md:text-red-100 text-red-900;
    }
  }
}

.pagy {
  @apply flex space-x-1 font-semibold text-sm text-gray-500;

  a:not(.gap) {
    @apply block rounded-lg px-3 py-1 bg-gray-200;

    &:hover {
      @apply bg-gray-300;
    }

    &:not([href]) {
      /* disabled links */
      @apply text-gray-300 bg-gray-100 cursor-default;
    }

    &.current {
      @apply text-white bg-gray-400;
    }
  }

  label {
    @apply inline-block whitespace-nowrap bg-gray-200 rounded-lg px-3 py-0.5;

    input {
      @apply bg-gray-100 border-none rounded-md;
    }
  }
}

.form-input-datepicker,
.form-input-search,
.form-input-monetary,
.form-input-phone,
.form-input-email {
  @apply pl-12;

  background-position: left 1rem center;
  background-size: 1.25rem 1.25rem;
  background-repeat: no-repeat;
}

.form-input-search {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'/%3E%3C/svg%3E");
}

turbo-frame#events-list[aria-busy] {
  @apply opacity-50;
}

.horizontal-padding {
  @apply px-4 sm:px-5 md:px-6 lg:px-8;
}

.vertical-padding {
  @apply py-4 sm:py-6 md:py-8;
}