ul[id^='autoComplete'][hidden] {
  @apply hidden
}

.autoComplete_wrapper {
  @apply w-full;
}

ul[id^='autoComplete'] {
  @apply w-full grid gap-1 p-1 shadow rounded-xl border border-gray-100 bg-white absolute left-0 z-10 my-1 overflow-y-auto max-h-[calc(100vh_-_80px)];
}

li[id^='autoComplete'] {
  @apply px-3 py-1.5 bg-gray-100 cursor-pointer rounded-lg;

  &:last-child {
    @apply mb-0;
  }

  & > mark {
    @apply p-0 font-semibold bg-transparent;
  }

  &[aria-selected=true],
  &:hover {
    @apply bg-sky-100;
  }
}